import React from 'react'
import {Carousel} from 'react-responsive-carousel'
import classnames from '@helpers/misc/classnames'
import dynamic from 'next/dynamic'

import styles from './styles.module.css'

const ReactPlayer = dynamic(() => import('react-player'))

export default function Images(props) {
  const {product} = props

  if ((!product.images || !product.images.length) && !product.urlVideo) return null

  if (product.images.length === 1) {
    return (
      <div className={styles.imgContainer}>
        {product.urlVideo ? (
          <ReactPlayer
            className={styles.reactPlayer}
            url={product.urlVideo}
            width="100%"
            height="100%"
            muted={true}
            loop={true}
            config={{
              vimeo: {playerOptions: {autoplay: true}},
              youtube: {playerVars: {autoplay: 1}}
            }}
            light={product.images[0].imageLarge}
          />
        ) : (
          <img
            className={classnames(styles.image, '!object-cover')}
            src={product.images[0].imageLarge}
            alt={product.altImg || product.name}
            title={product.titleImage || product.name}
          />
        )}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <Carousel showArrows emulateTouch showThumbs={false} showStatus={false}>
        {product.images.map((image, index) => (
          <div key={index} className={styles.slide}>
            {index === 0 && product.urlVideo ? (
              <ReactPlayer
                className={classnames(styles.reactPlayer, '!object-cover')}
                url={product.urlVideo}
                width="100%"
                height="100%"
                muted={true}
                loop={true}
                config={{
                  vimeo: {playerOptions: {autoplay: true}},
                  youtube: {playerVars: {autoplay: 1}}
                }}
                light={product.images[0].imageLarge}
              />
            ) : (
              <img
                className={classnames(styles.image, '!object-cover')}
                src={image.imageLarge}
                alt={product.altImg || product.name}
                title={product.titleImage || product.name}
              />
            )}
          </div>
        ))}
      </Carousel>
    </div>
  )
}
