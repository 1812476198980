import React from 'react'
import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import useState from '@hooks/useState'
import useWebsiteId from '@hooks/useWebsiteId'

import PageMeta from '../PageMeta'
import {SelectProductProps} from '..'

import Actions from './Actions'
import Details from './Details'
import {filterOptionsState} from './filterState'
import getDefaultState from './getDefaultState'
import getResume from './getResume'
import Images from './Images'
import Layout from './Layout'
import PromotionDetail from './PromotionDetail'
import useAdd from './useAdd'
import useProduct from './useProduct'

import styles from './styles.module.css'

export default function Inner(props: SelectProductProps) {
  const {close, cartItem, allowEdit = true} = props
  const websiteId = useWebsiteId()
  const data = useUserPreferences()
  const {deliverAt, storeId, menuId} = data
  const {website} = useWebsiteMenuInformation()
  const webLayout = website?.layout

  const add = useAdd()

  const product = useProduct({
    deliverAt,
    storeId,
    menuId,
    productId: props.product._id,
    websiteId
  })

  const [rawState, setState] = useState(getDefaultState(product, cartItem))
  const [loading, setLoading] = useState(false)
  if (!product) return null

  const state = filterOptionsState(rawState, product)
  const resume = getResume(props, state, product)

  const image = <Images product={product} />

  const details = (
    <div className={styles.content}>
      <Details
        state={state}
        product={product}
        onChange={changes =>
          setState({
            ...state,
            ...changes
          })
        }
      />
    </div>
  )

  const promotionDetail = (
    <div className={styles.actions}>
      <PromotionDetail product={product} amount={state.amount} />
    </div>
  )

  const actions = (
    <div id={props.open ? 'close-modal-product' : ''} className={styles.actions}>
      <Actions
        product={product}
        allowEdit={allowEdit}
        isOutOfStock={product.isOutOfStock}
        state={state}
        resume={resume}
        isAddingToCart={loading}
        add={() =>
          add({...props, ...(data?.menuId && {menuId: data?.menuId})}, state, product, resume, {
            setLoading
          })
        }
        cartItem={cartItem}
        close={close}
        layout={webLayout}
        onChange={changes =>
          setState({
            ...state,
            ...changes
          })
        }
      />
    </div>
  )

  return (
    <>
      <PageMeta product={product} />
      <Layout image={image} details={details} actions={actions} promotionDetail={promotionDetail} />
    </>
  )
}
