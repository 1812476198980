import getFilteredModifiers from './getFilteredModifiers'

export function filterOptionsState(state: any, product: any) {
  const filteredModifiers = getFilteredModifiers(product.modifiers, state)

  return {
    ...state,
    modifiers:
      state.modifiers?.map(modifier => {
        const modifierGroup = filteredModifiers.find(
          filteredModifier => filteredModifier._id === modifier.modifierId
        )
        return {
          ...modifier,
          optionsIds: modifier.optionsIds.filter(optionId => {
            return modifierGroup?.options?.find(option => option._id === optionId)
          })
        }
      }) || []
  }
}
