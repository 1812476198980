import {
  GetProductDetailsNonCachedQuery,
  GetProductDetailsWeb_CachedQuery,
  GetProductDetailsWeb_CachedQueryVariables
} from '@data/__generated__/types.main'
import hasWebpSupport from '@helpers/misc/hasWebpSupport'
import {useApolloQuery} from 'apollo-hooks'

import getMergedModifiers from './getMergedModifiers'
import {getProductDetails, getProductDetailsCached} from './productQueries.main'

export default function useProduct({websiteId, productId, menuId, storeId, deliverAt}) {
  const variables = {
    websiteId,
    productId,
    menuId,
    storeId,
    deliverAt,
    format: hasWebpSupport() ? 'webp' : 'jpeg'
  }
  const {product: productCached} = useApolloQuery<
    GetProductDetailsWeb_CachedQuery,
    GetProductDetailsWeb_CachedQueryVariables
  >({
    query: getProductDetailsCached,
    variables,
    omit: !productId
  })

  const {product: productNonCached} = useApolloQuery<
    GetProductDetailsNonCachedQuery,
    GetProductDetailsWeb_CachedQueryVariables
  >({
    query: getProductDetails,
    variables,
    omit: !productId
  })

  if (!productId) return null

  return {
    ...productNonCached,
    ...productCached,
    modifiers: getMergedModifiers(productCached, productNonCached)
  }
}
